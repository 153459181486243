<template>
  <div class="wrapper">
    <div>
      <!-- personname: this.chaxun.personname,	//姓名	query	String		
        personcard: this.chaxun.personcard,	//身份证号	query	String		
        censusaddr: this.chaxun.censusaddr,	//户籍地	query	String		
        housename: this.chaxun.housename,	//居住地址	query	String		
        regionname: this.chaxun.regionname,	//小区名称	query	String		
        starttime: this.chaxun.time && this.chaxun.time.length ?this.chaxun.time[0]:'',	//录入系统起始时间(YYYYMMDD) 默认本月1号	query	String		
        endtime: this.chaxun.time && this.chaxun.time.length ?this.chaxun.time[1]:'',	//录入系统结束时间(YYYYMMDD) 默认本月最后1天	query	String		
        doing: this.chaxun.doing,	//操作类型传 字符串add或者del 不能为空	query	string		 -->
        <el-form :model="chaxun" :inline="true">
          <el-form-item label="店铺名称：">
            <el-input class="shuru" v-model="chaxun.showname" clearable></el-input>
          </el-form-item>
          <el-form-item label="店铺管理人：">
            <el-input class="shuru" v-model="chaxun.siteman" clearable></el-input>
          </el-form-item>
          <el-form-item label="管理人电话：">
            <el-input class="shuru" v-model="chaxun.sitemanphone" clearable
              oninput="value=value.replace(/^(0+)|[^\d]+/g,'')"></el-input>
          </el-form-item>
          <el-form-item label="网格员：">
            <el-input class="shuru" v-model="chaxun.managename" clearable></el-input>
          </el-form-item>

          <el-form-item label="是否有营业执照：">
            <el-select v-model="chaxun.realcodeflag" clearable placeholder="请选择">
              <el-option label="有" value="有"></el-option>
              <el-option label="没有" value="没有"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="营业执照">
            <el-input class="shuru" v-model="chaxun.realcode" clearable></el-input>
          </el-form-item>

          <el-form-item label="是否三合一场所">
            <el-select v-model="chaxun.sanheyi" clearable placeholder="请选择">
              <el-option label="是" value="是"></el-option>
              <el-option label="不是" value="不是"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="任务情况">
            <el-select v-model="chaxun.taskflag" clearable placeholder="请选择">
              <el-option label="已建任务" value="0"></el-option>
              <el-option label="未建任务" value="1"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="认领情况">
            <el-select v-model="chaxun.renlingflag" clearable placeholder="请选择">
              <el-option label="已认领" value="0"></el-option>
              <el-option label="未认领" value="1"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="时间">
            <el-date-picker
              v-model="chaxun.time"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyyMMdd"
              format="yyyy-MM-dd">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="操作类型">
            <el-select v-model="chaxun.doing" placeholder="请选择">
              <el-option label="新增场所" value="add"></el-option>
              <el-option label="删除场所" value="del"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSearch">查询</el-button>
            <el-button type="primary" @click="exportExl">导出</el-button>
          </el-form-item>
        </el-form>
    </div>
    <div>
      
      <el-table
        v-loading="loading"
        :data="tableData"
        stripe
        border
        style="width: 100%">
        <el-table-column type="index" width="70" align="center" height show-overflow-tooltip>
							<template scope="scope"><span>{{scope.$index+(currentPage - 1) * fenye.pagesize + 1}}
								</span></template>
						</el-table-column>
						<el-table-column prop="showname" label="店铺名称" align="center" height show-overflow-tooltip>
						</el-table-column>
						<el-table-column prop="ninetypename" label="场所类型" align="center" height show-overflow-tooltip>
						</el-table-column>
						<el-table-column prop="address" label="地址" align="center" height show-overflow-tooltip>
						</el-table-column>
						<el-table-column prop="realcode" label="营业执照" align="center" height show-overflow-tooltip>
						</el-table-column>
						<el-table-column prop="sanheyi" label="是否三合一" align="center" width="90" height show-overflow-tooltip>
						</el-table-column>
						<el-table-column prop="sanheyi" label="任务情况" align="center" height show-overflow-tooltip>
							<template slot-scope="scope">
								<span v-if="scope.row.taskuuid">已建任务</span>
								<span v-else>未建任务</span>
							</template>
						</el-table-column>
						<el-table-column prop="siteman" label="店铺管理人"  width="100" align="center" height show-overflow-tooltip>
						</el-table-column>
						<el-table-column prop="sitemanphone" label="管理人电话"  width="120" align="center" height show-overflow-tooltip>
						</el-table-column>
						<el-table-column prop="managename" label="操作人" align="center" height show-overflow-tooltip>
						</el-table-column>
            <el-table-column prop="doingtype" label="操作类型" align="center" height show-overflow-tooltip>
						</el-table-column>
            <el-table-column prop="doingdescinfo" label="操作描述" align="center" height show-overflow-tooltip>
						</el-table-column>
            <el-table-column prop="dotime" width="200" label="操作时间" align="center" height show-overflow-tooltip>
						</el-table-column>
      </el-table>
    </div>
    <div class="pagebox">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="fenye.pagesizes"
        :page-size="fenye.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="fenye.totalnum">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'
import { nineSiteFlowPace, exportnineSiteFlow } from '@/api/ninesite.js'
export default {
  name:"",
  components:{},
  props:{},
  data(){
    return {
      tableData: [],
      chaxun: {
        ninetype: '',
        showname: '',
        siteman: '',
        sitemanphone: '',
        managename: '',
        realcodeflag: '',	
        realcode: '',	
        sanheyi: '',	
        taskflag: '',
        renlingflag: '',
        time: [],
        starttime: '',
        endtime: '',
        doing: 'add'
      },
      fenye: {
        pagesizes: [10, 20, 30, 40],
        pagesize: 10,
        totalnum: 0
      },
      currentPage: 1,
      loading: false
    }
  },
  watch:{},
  created(){},
  mounted(){
    this.chaxun.time = [this.getCurrentMonthFirst(), this.getLastDayOfMonth()]
    this.onSearch();
  },
  computed:{},
  methods:{
    // 获取当月的第一天
    getCurrentMonthFirst() {
      let date = new Date();
      date.setDate(1);
      let month = parseInt(date.getMonth() + 1);
      let day = date.getDate();
      if (month < 10) {
          month = '0' + month
      }
      if (day < 10) {
          day = '0' + day
      }
      return date.getFullYear() + '' + month + '' + day;
    },
    // 获取当月的最后一天
    getLastDayOfMonth() {
      let date = new Date();
      let currentMonth = date.getMonth();
      let nextMonth = ++currentMonth;
      let nextMonthFirstDay = new Date(date.getFullYear(), nextMonth, 1);
      let oneDay = 1000 * 60 * 60 * 24; //js单位是毫秒，60*1000转化为秒
      let lastTime = new Date(nextMonthFirstDay - oneDay);
      let month = parseInt(lastTime.getMonth() + 1);
      let day = lastTime.getDate();
      if (month < 10) {
          month = '0' + month
      }
      if (day < 10) {
          day = '0' + day
      }
      return date.getFullYear() + '' + month + '' + day;
    },
    onSearch(){
      this.currentPage = 1;
      this.getList();
    },
    //导出
    exportExl(){
      if(!this.chaxun.time || !this.chaxun.time.length){
        this.$message.warning('请选择时间范围');
        return false;
      }
      exportnineSiteFlow({
        'x-access-token': this.GLOBAL.token(),
				"userid": this.GLOBAL.adminId(),
        personname: this.chaxun.personname,	//姓名	query	String		
        personcard: this.chaxun.personcard,	//身份证号	query	String		
        censusaddr: this.chaxun.censusaddr,	//户籍地	query	String		
        housename: this.chaxun.housename,	//居住地址	query	String		
        regionname: this.chaxun.regionname,	//小区名称	query	String		
        starttime: this.chaxun.time && this.chaxun.time.length ?this.chaxun.time[0]:'',	//录入系统起始时间(YYYYMMDD) 默认本月1号	query	String		
        endtime: this.chaxun.time && this.chaxun.time.length ?this.chaxun.time[1]:'',	//录入系统结束时间(YYYYMMDD) 默认本月最后1天	query	String		
        doing: this.chaxun.doing,	//操作类型传 字符串add或者del 不能为空	query	string		
        page: this.currentPage,	//页码	query	int		
        pagesize: this.fenye.pagesize,	//每页显示多少条
      }).then((res) => {
      if (res.result == '200') {
        FileSaver.saveAs(res.detail, res.detail.split('/downfile/')[1]);
      } else {
        this.$message.error(res.description);
      }
    })
    },
    getList() {
      if(!this.chaxun.time || !this.chaxun.time.length){
        this.$message.warning('请选择时间范围');
        return false;
      }
      
      this.loading = true;
      nineSiteFlowPace({
        'x-access-token': this.GLOBAL.token(),
				userid: this.GLOBAL.adminId(),
        ninetype: this.chaxun.ninetype,
        showname: this.chaxun.showname,
        siteman: this.chaxun.siteman,
        sitemanphone: this.chaxun.sitemanphone,
        managename: this.chaxun.managename,
        realcodeflag: this.chaxun.realcodeflag,
        realcode: this.chaxun.realcode,
        sanheyi: this.chaxun.sanheyi,
        taskflag: this.chaxun.taskflag,
        renlingflag: this.chaxun.renlingflag,	
        starttime: this.chaxun.time && this.chaxun.time.length ?this.chaxun.time[0]:'',
        endtime: this.chaxun.time && this.chaxun.time.length ?this.chaxun.time[1]:'',
        doing: this.chaxun.doing,
        page: this.currentPage,
        pageSize: this.fenye.pagesize
      }).then(res=>{
        this.loading = false;
        if(res.result == 200){
          this.tableData = res.detail.list;
          this.fenye.totalnum = res.detail.totalRow;
        } else {
          this.$message.error(res.description);
        }
      })
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.fenye.pagesize = val;
      this.currentPage = 1;
      this.getList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getList();
    },
  }
}
</script>

<style lang="scss" scoped>
  .pagebox{
    padding: 20px 0;
  }
</style>