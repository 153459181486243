import request from '@/utils/request'


// 巡防任务列表
export function patrolTaskList(params) {
  return request({
    url: '/nineTask/page',
    method: 'get',
    params:params
  })
}

//新增任务
export function addTask(params) {
  return request({
    url: '/nineTask/add',
    method: 'get',
    params:params
  })
}

//任务流水
export function taskFlow(params) {
  return request({
    url: '/nineTask/taskLog',
    method: 'get',
    params:params
  })
}

//中止任务
export function taskEnd(params) {
  return request({
    url: '/nineTask/end',
    method: 'get',
    params:params
  })
}





//任务详情
export function taskDetail(params) {
  return request({
    url: '/nineTask/taskDetail',
    method: 'get',
    params:params
  })
}

//场所类型
export function nineSiteTaskType(params) {
  return request({
    url: '/nineTask/NineSiteTaskTree',
    method: 'get',
    params:params
  })
}

//专项巡防列表
export function getLogIdearType(params) {
  return request({
    url: '/common/getLogIdear',
    method: 'get',
    params:params
  })
}

//专项巡防删除
export function logIdearDel(params) {
  return request({
    url: '/codetable/del',
    method: 'get',
    params: params
  })
}

//专项巡防新增
export function logIdearAdd(params) {
  return request({
    url: '/codetable/Add',
    method: 'get',
    params: params
  })
}

//巡防任务列表导出
export function exportNineSiteList(params) {
  return request({
    url: '/nineTask/pageExport',
    method: 'get',
    params: params
  })
}

//巡防任务流水列表导出
export function exportLogList(params) {
  return request({
    url: '/nineTask/exportLogList',
    method: 'get',
    params: params
  })
}





		




//场所详情
export function nineSiteDetail(params) {
  return request({
    url: '/nineSite/NineSiteDetailInFo',
    method: 'get',
    params:params
  })
}



//场所删除
export function nineSiteDel(params) {
  return request({
    url: '/nineSite/NineSiteDel',
    method: 'get',
    params:params
  })
}

//场所操作流水
export function nineSiteFlowPace(params) {
  return request({
    url: '/nineSite/DoingNineSitePage',
    method: 'get',
    params: params
  })
}

//操作流水导出
export function exportnineSiteFlow(data) {
	return request({
		url: '/nineSite/ExportDoingNineSite',
		method: 'get',
		params: data
	})
}

//场所统计
export function totalNineSite(params) {
  return request({
    url: '/nineSite/NineSiteCount',
    method: 'get',
    params:params
  })
}

//专项汇报列表
export function appNineSpecialPage(params) {
  return request({
    url: '/nineaction/appNineSpecialPage',
    method: 'get',
    params:params
  })
}
//专项汇报删除
export function appNineSpecialDel(params) {
  return request({
    url: '/nineaction/appNineSpecialDel',
    method: 'get',
    params:params
  })
}

//专项工作列表
export function meetPage(params) {
  return request({
    url: '/ninemeet/MeetPage',
    method: 'get',
    params:params
  })
}

//专项工作新增
export function meetAdd(params) {
  return request({
    url: '/ninemeet/MeetAdd',
    method: 'get',
    params:params
  })
}

//专项工作删除
export function meetUpdate(params) {
  return request({
    url: '/ninemeet/MeetUpdate',
    method: 'get',
    params:params
  })
}

//专项工作修改
export function meetDel(params) {
  return request({
    url: '/ninemeet/MeetDel',
    method: 'get',
    params:params
  })
}


//总任务数
export function taskTotal(params) {
  return request({
    url: '/nineTask/task',
    method: 'get',
    params:params
  })
}


//任务周期
export function taskPeriod(params) {
  return request({
    url: '/nineTask/taskPeriod',
    method: 'get',
    params:params
  })
}



//巡防人员列表
export function xunfangPersonList(data) {
	return request({
		url: '/house/xunfangPersonList',
		method: 'get',
		params: data
	})
}


//流水导出
export function patrolTaskExport(data) {
	return request({
		url: '/nineTask/exportList',
		method: 'post',
		params: data
	})
}

//专项工作导出
export function meetingExport(data) {
	return request({
		url: '/ninemeet/exportList',
		method: 'get',
		params: data
	})
}

//群租房巡防-----
//任务列表
export function groupRentalList(data) {
	return request({
		url: '/groupRental/page',
		method: 'get',
		params: data
	})
}

//巡防问题列表
export function groupRentalProblemList(data) {
	return request({
		url: '/groupRental/problemPage',
		method: 'get',
		params: data
	})
}


//任务审核列表
export function groupRentalAuditList(data) {
	return request({
		url: '/groupRental/auditPage',
		method: 'get',
		params: data
	})
}